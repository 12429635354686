<template>
    <div class="student-wrapper">
        <div class="header">
            <!-- <el-button class="train-manage" @click="goToTrainManage" v-if="isType === 2">训练管理</el-button> -->
            <div class="header-left">
                <!--倒计时-->
                <CountDown :endDialog.sync="endDialog" @changeStatus="changeStatusCompetition"></CountDown>
            </div>
            <div class="header-right">
                <div class="exit-btn" @click="studentLogout">
                    <i class="iconfont">&#xe648;</i>
                    <div>返回主页面</div>
                </div>
            </div>
        </div>
        <el-scrollbar class="container" :native="false">
            <template v-if="tmlList.length === 1">
                <div class="box-one" :class="isStart ? 'not-allowed' : ''"
                     v-for="(oneItem , oneIndex) in tmlList" :key="`tmlOne_${oneIndex}`">
                    <div class="one-item one-left"
                         :style="{backgroundImage: `url(${moduleList[oneItem.tmpl_module_type - 1].cover})`}">
                        <div class="left-title">{{moduleList[oneItem.tmpl_module_type - 1].title}}</div>
                        <div class="no-begin" v-if="isStart">
                            <img :src="require('../../assets/images/studentIndex/no-begin.png')" alt="">
                            <div class="prompt">
                                比赛未开始<br>
                                敬请期待
                            </div>
                        </div>
                    </div>
                    <div class="one-item one-content">
                        <div class="wrapper">
                            <div class="content-title">{{name}}</div>
                            <div class="content-introduce" :title="introduce">{{introduce}}</div>
                            <router-link :to="{path: moduleList[oneItem.tmpl_module_type - 1].route}"
                                         style="display: inline-block">
                                <el-button type="primary" round :disabled="isStart">进入比赛</el-button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="multiple-content">
                    <div class="multiple-title">{{name}}</div>
                    <div class="multiple-introduce" :title="introduce">{{introduce}}</div>
                    <template v-if="tmlList.length === 2">
                        <div class="multiple-box box-two" :class="isStart ? 'not-allowed' : ''">
                            <router-link :to="{path: moduleList[twoItem.tmpl_module_type - 1].route}"
                                         class="mul-item"
                                         :style="{backgroundImage: `url(${moduleList[twoItem.tmpl_module_type - 1].cover})`}"
                                         v-for="(twoItem, twoIndex) in tmlList" :key="`tmlTwo_${twoIndex}`">
                                <div class="mul-title">{{moduleList[twoItem.tmpl_module_type - 1].title}}</div>
                                <div class="no-begin" v-if="isStart">
                                    <img :src="require('../../assets/images/studentIndex/no-begin.png')" alt="">
                                    <div class="prompt">
                                        比赛未开始<br>
                                        敬请期待
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </template>
                    <template v-else-if="tmlList.length === 3">
                        <div class="multiple-box box-three" :class="isStart ? 'not-allowed' : ''"
                             style="max-height: 450px">
                            <router-link :to="{path: moduleList[threeItem.tmpl_module_type - 1].route}"
                                         class="mul-item"
                                         :style="{backgroundImage: `url(${moduleList[threeItem.tmpl_module_type - 1].cover})`}"
                                         v-for="(threeItem, threeIndex) in tmlList" :key="`tmlThree_${threeIndex}`">
                                <div class="mul-title">{{moduleList[threeItem.tmpl_module_type - 1].title}}</div>
                                <div class="no-begin" v-if="isStart">
                                    <img :src="require('../../assets/images/studentIndex/no-begin.png')" alt="">
                                    <div class="prompt">
                                        比赛未开始<br>
                                        敬请期待
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </template>
                    <template v-else-if="tmlList.length === 4">
                        <div class="multiple-box box-four" :class="isStart ? 'not-allowed' : ''"
                             style="max-height: 450px">
                            <router-link :to="{path: moduleList[fourItem.tmpl_module_type - 1].route}"
                                         class="mul-item"
                                         :style="{backgroundImage: `url(${moduleList[fourItem.tmpl_module_type - 1].cover})`}"
                                         v-for="(fourItem, fourIndex) in tmlList" :key="`tmlFour_${fourIndex}`">
                                <div class="mul-title">{{moduleList[fourItem.tmpl_module_type - 1].title}}</div>
                                <div class="no-begin" v-if="isStart">
                                    <img :src="require('../../assets/images/studentIndex/no-begin.png')" alt="">
                                    <div class="prompt">
                                        比赛未开始<br>
                                        敬请期待
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </template>
                    <template v-else-if="tmlList.length === 5">
                        <div class="multiple-box box-five" :class="isStart ? 'not-allowed' : ''"
                             style="max-height: 450px">
                            <router-link :to="{path: moduleList[fiveItem.tmpl_module_type - 1].route}"
                                         class="mul-item"
                                         :style="{backgroundImage: `url(${moduleList[fiveItem.tmpl_module_type - 1].cover})`}"
                                         v-for="(fiveItem, fiveIndex) in tmlList" :key="`tmlFive_${fiveIndex}`">
                                <div class="mul-title">{{moduleList[fiveItem.tmpl_module_type - 1].title}}</div>
                                <div class="no-begin" v-if="isStart">
                                    <img :src="require('../../assets/images/studentIndex/no-begin.png')" alt="">
                                    <div class="prompt">
                                        比赛未开始<br>
                                        敬请期待
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </template>
                    <template v-else>
                        <div class="multiple-box box-six" :class="isStart ? 'not-allowed' : ''">
                            <router-link :to="{path: moduleList[sixItem.tmpl_module_type - 1].route}"
                                         class="mul-item"
                                         :style="{backgroundImage: `url(${moduleList[sixItem.tmpl_module_type - 1].cover})`}"
                                         v-for="(sixItem, sixIndex) in tmlList" :key="`tmlSix_${sixIndex}`">
                                <div class="mul-title">{{moduleList[sixItem.tmpl_module_type - 1].title}}</div>
                                <div class="no-begin" v-if="isStart">
                                    <img :src="require('../../assets/images/studentIndex/no-begin.png')" alt="">
                                    <div class="prompt">
                                        比赛未开始<br>
                                        敬请期待
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </template>
                </div>
            </template>
        </el-scrollbar>
        <el-dialog title="提示" :visible.sync="endDialog" width="550px" custom-class="end-dialog" @close="studentLogout">
            <div class="end-competition">
                <img :src="require('../../assets/images/studentIndex/no-begin.png')" alt="">
                <div class="text">竞赛已结束~</div>
            </div>
            <div slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" class="btn-red" @click="studentLogout">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import CountDown from '@/components/student/CountDown.vue'

    export default {
        components: {
            CountDown
        },
        data() {
            return {
                username: '',
                userIcon: '',
                //比赛介绍
                introduce: '',
                //比赛名称
                name: '',
                //比赛时间
                beginTime: '',
                endTime: '',
                isStart: true,
                isType: 1,
                //模块列表
                tmlList: [],
                moduleList: [
                    {
                        title: '店铺装修', // 父级：网店开设装修
                        cover: require('../../assets/images/studentIndex/zhuangxiu.png'),
                        route: '/student/storedecorate/index'
                    },
                    {
                        title: '网店运营推广', // *挪到一级导航
                        cover: require('../../assets/images/studentIndex/yunying.png'),
                        route: '/student/operationpromotion/taskdetail'
                    },
                    {
                        title: '客户服务与问答', // 挪到一级导航（旧客服）
                        cover: require('../../assets/images/studentIndex/kefu.png'),
                        route: '/student/kefu'
                    },
                    {
                        title: '店铺运营分析答辩', // 挪到一级导航
                        cover: require('../../assets/images/studentIndex/dabian.png'),
                        route: '/student/storeoperation/index'
                    },
                    {
                        title: '数据分析', // 挪到一级导航
                        cover: require('../../assets/images/studentIndex/data_analysis.png'),
                        route: '/student/customerInfo/index'
                    },
                    {
                        title: '客服数据分析与应用', // 挪到一级导航
                        cover: require('../../assets/images/studentIndex/data_analysis.png'),
                        route: '/student/customerInfo/index'
                    },
                    {
                        title: '客户服务', // 父级：直播销售及客户服务（新客服）
                        cover: require('../../assets/images/studentIndex/kefu_new.png'),
                        route: '/student/newKefu'
                    },
                    {
                        title: '网店规划与注册', // 父级：网店开设装修
                        cover: require('../../assets/images/studentIndex/register.png'),
                        route: '/student/storeRegister'
                    },
                    {
                        title: '直播销售', // 父级：直播销售及客户服务
                        cover: require('../../assets/images/studentIndex/zhibo.png'),
                        route: '/student/liveStreamingSales/goodsManagement'
                    },
                ],
                // 比赛结束弹窗
                endDialog: false,
                comType: 1,//竞赛：1；训练：2
            }
        },
        mounted() {
            this.username = localStorage.getItem('username');
            let userIcon = localStorage.getItem('headerImg');
            if (userIcon !== 'null') {
                this.userIcon = userIcon;
            }
            if (this.username === null) {
                this.$message({
                    type: 'warning',
                    message: '请先登录',
                    onClose: () => {
                        this.studentLogout();
                    }
                });
            }
            this.getStudentComeInfo();

        },
        methods: {
            changeStatusCompetition(val) {
                this.isStart = val;
            },
            //获取比赛信息
            getStudentComeInfo() {
                this.$http.axiosGet(this.$api.studentComInfo, (res) => {
                    if (res.code === 200) {
                        if (res.data.competition) {
                            if (res.data.admin_count !== null) {
                                localStorage.setItem('admin_count', res.data.admin_count)
                            }
                            this.comType = res.data.competition.type
                            this.tmlList = res.data.competition.modules.filter(item => {
                                if (item.tmpl_module_type === 9) localStorage.setItem('liveMarketModuleId', item.tmpl_module_id);
                                let res = {
                                    '1': item.tmpl_module_type === 1,
                                    '8': item.tmpl_module_type === 8,
                                    '1,8': item.tmpl_module_type === 1 ||  item.tmpl_module_type === 8,
                                    '7': item.tmpl_module_type === 7,
                                    '9': item.tmpl_module_type === 9,
                                    '7,9': item.tmpl_module_type === 7 ||  item.tmpl_module_type === 9
                                }
                                // return item.tmpl_module_type != 5
                                return res[this.$route.query.modules]
                            });
                            this.introduce = res.data.competition.introduce;
                            this.name = res.data.competition.name;
                            //获取开始时间
                            this.beginTime = res.data.competition.begin_time;
                            this.endTime = res.data.competition.end_time;
                            // 获取当前时间
                            let newTime = res.data.competition.time;
                            this.$lockr.set('begin_time', res.data.competition.begin_time);
                            this.$lockr.set('end_time', res.data.competition.end_time);
                            this.$lockr.set('competition_type', res.data.competition.type);
                            this.isType = Number(res.data.competition.type);

                            localStorage.setItem('com_id', res.data.competition.id)

                            //开始比赛
                            if ((newTime - this.beginTime) > 0) {
                                this.isStart = false;
                            } else {
                                this.isStart = true;
                            }
                            // 结束比赛的弹窗
                            if (newTime - this.endTime > 0) {
                                this.endDialog = true
                            }
                        } else {
                            this.$message.warning('暂无竞赛信息')
                            this.$router.go(-1)
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //进入训练管理
            goToTrainManage() {
                this.$router.push('/student/trainmanage/index');
            },
            //退出
            studentLogout() {
                this.$router.push('/student/index')
            },
        }
    }
</script>

<style scoped lang="scss">
    .student-wrapper {
        .header {
            display: flex;
            width: 100%;
            height: 60px;
            background: #fff;
            position: relative;
            align-items: center;

            .train-manage {
                border: 1px solid #FD4446;
                padding: 10px 11px;
                position: absolute;
                left: 60px;
                /*top: 22px;*/
                color: #FD4446;

                &:hover {
                    color: #fd4446d9;
                    background-color: #fff;
                    border: 1px solid #fd4446d9;
                }
            }

            .header-left {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 1%;
                flex: 1;
                color: #FD4446;
                font-weight: 500;
                font-size: 18px;
            }

            .header-right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-right: 30px;

                .user-info {
                    display: flex;
                    align-items: center;

                    .user-avatar {
                        width: 32px;
                        height: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                        border-radius: 50%;
                        border: 1px solid #eee;
                        box-sizing: border-box;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    .user-name {
                        margin-left: 6px;
                    }
                }

                .exit-btn {
                    margin-left: 24px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    transition: all .3s;
                    height: 24px;

                    .iconfont {
                        font-size: 24px;
                        color: #1122D8;
                    }

                    div {
                        height: 24px;
                        line-height: 30px;
                    }

                    &:hover {
                        color: #FD4446;
                    }
                }
            }
        }

        .container {
            width: 100%;
            height: calc(100vh - 60px);
            background: url("../../assets/images/studentIndex/index-bg.png") no-repeat bottom center, linear-gradient(#04BEFE, #4482EB);
            background-size: cover;
            position: relative;

            /deep/ .el-scrollbar__wrap {
                overflow-x: hidden;

                .el-scrollbar__view {
                    min-width: 800px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }

            .box-one {
                width: 100%;
                max-width: 1200px;
                margin: 0 auto;
                height: calc(100vh - 300px);
                background: #fff;
                border-radius: 20px;
                display: flex;

                .one-item {
                    &.one-left {
                        width: 56.6666%;
                        border-radius: 20px 0 0 20px;
                        background-color: #EDFDFF;
                        background-repeat: no-repeat;
                        background-position: bottom center;
                        /*background-size: 90% auto;*/
                        background-size: auto 65%;

                        .left-title {
                            color: #3096F2;
                            font-size: 36px;
                            text-align: center;
                            margin-top: 8%;
                        }
                    }

                    &.one-content {
                        width: 1%;
                        flex: 1;
                        padding: 0 70px 0 40px;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;

                        .content-title {
                            font-size: 24px;
                            color: #343434;
                        }

                        .content-introduce {
                            margin: 25px 0 40px;
                            color: #666;
                            font-size: 18px;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 10;
                            overflow: hidden;
                        }
                    }
                }

                &.not-allowed {
                    .one-item {
                        background-image: none !important;

                        &.one-left {
                            .left-title {
                                color: #999;
                            }

                            .no-begin {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                padding: 0 20px;

                                img {
                                    max-width: 100%;
                                    margin-top: 20px;
                                }

                                .prompt {
                                    margin-top: 20px;
                                    text-align: center;
                                    font-size: 16px;
                                    color: #999;
                                }
                            }
                        }

                        &.one-content {
                            .wrapper {
                                a {
                                    pointer-events: none;
                                    cursor: not-allowed;
                                }
                            }
                        }
                    }
                }
            }

            .multiple-content {
                width: 100%;
                max-width: 1200px;
                margin: 0 auto;
                height: 100%;
                display: flex;
                flex-direction: column;

                .multiple-title {
                    text-align: center;
                    font-size: 36px;
                    color: #fff;
                    margin-top: 60px;
                    font-weight: 500;
                    line-height: 1;
                }

                .multiple-introduce {
                    font-size: 18px;
                    margin-top: 30px;
                    line-height: 30px;
                    color: #fff;
                    height: 90px;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                }

                .multiple-box {
                    height: 1%;
                    flex: 1;
                    margin: 0 0 60px;
                    display: flex;
                    /*max-height: 450px;*/
                    .mul-item {
                      margin-top: 20px;
                        border-radius: 20px;
                        background-color: #EDFDFF;
                        background-repeat: no-repeat;
                        background-position: bottom center;
                        /*background-size: 90% auto;*/
                        background-image: url("../../assets/images/studentIndex/zhuangxiu.png");
                        /*position: relative;*/
                        .mul-title {
                            color: #2F95F1;
                            font-size: 30px;
                            text-align: center;
                            margin-top: 8%;
                        }
                    }

                    &.not-allowed {
                        .mul-item {
                            pointer-events: none;
                            cursor: not-allowed;
                            background-image: none !important;

                            .mul-title {
                                color: #999;
                            }

                            .no-begin {
                                padding: 0 20px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                img {
                                    display: block;
                                    max-width: 100%;
                                    margin-top: 40px;
                                }

                                .prompt {
                                    margin-top: 20px;
                                    text-align: center;
                                    font-size: 16px;
                                    color: #999;
                                }
                            }
                        }
                    }

                    &.box-two {
                        .mul-item {
                            /*width: 34%;*/
                            /*margin: 0 8%;*/
                            /*background-size: auto 63%;*/
                            /*width: calc(50% - 60px);*/
                            width: 40%;
                            margin: 0 5%;
                            background-size: auto 60%;
                        }

                        &.not-allowed {
                            .mul-item {
                                .no-begin {
                                    img {
                                        max-width: 70%;
                                    }
                                }
                            }
                        }
                    }

                    &.box-three {
                        margin-left: -60px;

                        .mul-item {
                            width: calc(33.333333% - 60px);
                            margin-left: 60px;
                            background-size: auto 60%;

                            .mul-title {
                                margin-top: 12%;
                                font-size: 28px;
                            }
                        }
                    }

                    &.box-four {
                        margin-left: -30px;

                        .mul-item {
                            width: calc(25% - 30px);
                            margin-left: 30px;
                            /*background-size: auto 55%;*/
                            background-size: 100% auto;

                            .mul-title {
                                font-size: 22px;
                                margin-top: 30%;
                            }
                        }
                    }


                    &.box-five {
                        margin-left: -20px;

                        .mul-item {
                            width: calc(20%);
                            margin-left: 20px;
                            /*background-size: auto 55%;*/
                            background-size: 100% auto;

                            .mul-title {
                                font-size: 20px;
                                margin-top: 32%;
                            }
                        }
                    }


                    &.box-six {
                        margin-left: -20px;
                        flex-wrap: wrap;
                        .mul-item {
                            width: calc(20% - 20px);
                            margin-left: 20px;
                            /*background-size: auto 55%;*/
                            background-size: 100% auto;

                            .mul-title {
                                font-size: 18px;
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    /deep/ .end-dialog {
        .el-dialog__header {
            border-bottom: 1px solid #eee;
        }

        .el-dialog__headerbtn:focus .el-dialog__close,
        .el-dialog__headerbtn:hover .el-dialog__close {
            color: #FD4446;
        }

        .end-competition {
            text-align: center;

            img {
                height: 170px;
            }

            .text {
                margin-top: 28px;
                color: #666;
                font-size: 18px;
            }
        }
    }
</style>